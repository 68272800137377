import { axiosInstance } from '../../js/axiosinstance'
import state from './state'
export const authCodeLogin = (state, payload) => {
    return new Promise((resolve, reject) => {
      axiosInstance.post('/authCodeLogin', payload, { crossDomain: true })
        .then(res => {
          state.commit('setAuthCodeLogin', res)
          resolve(res)
        }, error => {
          reject(error)
        })
    })
  }