//store/index.js

import { createStore } from 'vuex'
import users from './users'
// import crypto from './modules/crypto'

export default createStore({
    modules: {
        users
        // crypto
    },
})