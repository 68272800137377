import Vue from 'vue'
import axios from 'axios'
import configApiUrl from './apiurl.js'
const axiosInstance = axios.create({
  baseURL: configApiUrl.baseURL,
  headers: {
    crossDomain: true,
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true'
  }
})
axiosInstance.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${localStorage.token}`
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
// Vue.prototype.$axios = axiosInstance
export { axiosInstance }
