// change only this lines below
const env = 'LOCAL' // valid values are LOCAL - if localhost, DEV - if 100 server, QA - if QA server, LIVE - for live server
const versionNumber = 28 // change this to today's date number
// don't change these lines below
const urls = [
  { id: 'LOCAL', name: 'Local Host - Santhosh', siteURL: 'http://localhost:8080/musifie/#/', baseURL: 'http://localhost/musifie/public/api/v1', broadCast: { key: 'websocketkey', encrypted: false, forceTLS: false, endPoint: 'http://smrdev.com/musifie/public/broadcasting/auth', port: 6001, host: 'smrdev.com' } },
  { id: 'DEV', name: '100 Server', siteURL: 'http://localhost:8080/musifie/#/', baseURL: 'http://192.168.2.100/musifie1/api/v1', broadCast: { key: 'websocketkey', encrypted: false, forceTLS: false, endPoint: 'http://192.168.2.100/musifie1/broadcasting/auth', port: 6001, host: 'smrdev.com' } },
  { id: 'QA', name: 'QA Musifie', siteURL: 'https://qa.musifie.com/#/', baseURL: 'https://qa-api.musifie.com/api/v1', broadCast: { key: '5F72AVxdT4Hgh', encrypted: true, forceTLS: true, endPoint: 'https://qa-api.musifie.com/broadcasting/auth', port: 443, host: 'qa-api.musifie.com' } },
  { id: 'LIVE', name: 'Live Musifie', siteURL: 'https://app.musifie.com/#/', baseURL: 'https://api.musifie.com/api/v1', broadCast: { key: '5F72AVxdT4Hgh', encrypted: true, forceTLS: true, endPoint: 'https://api.musifie.com/broadcasting/auth', port: 443, host: 'api.musifie.com' } }
]
const d = new Date()
const mm = d.getMonth() + 1
const version = '1.0.' + mm + '.' + versionNumber
let baseURL = ''
let broadCast = ''
let siteURL = ''
urls.forEach(function (p) {
  if (p.id === env) {
    baseURL = p.baseURL
    siteURL = p.siteURL
    broadCast = p.broadCast
  }
})
const configApiUrl = {}
configApiUrl.version = version
configApiUrl.baseURL = baseURL
configApiUrl.broadCast = broadCast
configApiUrl.siteURL = siteURL
export default configApiUrl
